import React, { useState, useRef } from "react";
import Layout from "./layout/Layout";
import { subscribeUsersBatch } from '../services/user';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
// Call toast.configure() to configure global settings
toast.configure({
  position: 'top-right', // Position of toast messages
  autoClose: 5000,           // Auto close time in milliseconds
  hideProgressBar: false,     // Whether to show progress bar
  closeOnClick: true,         // Close toast on click
  pauseOnHover: true,         // Pause timer when hovering over toast
  draggable: true,            // Make toast draggable
  draggablePercent: 60,       // Percentage of toast width draggable
});

const UploadBase = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // const handleFileUpload = async (e) => {
  //   if(file){

  //    // Extract file extension
  // const fileExtension = file.name.split('.').pop().toLowerCase();

  // // Check if the file type is valid
  // if (fileExtension !== 'csv' && fileExtension !== 'xls' && fileExtension !== 'xlsx') {
  //   toast.error("Please select a valid CSV or Excel file");
  //   return;
  // }
  //     e.preventDefault();
  //     await subscribeUsersBatch(file).then(res => {
  //       console.log("res", res);
  //       fileInputRef.current.value='';
  //       toast.success("File Uploaded Successfully");
  //     }).catch(err => {
  //       console.log("Inside failure", err);
  //     });
  //   } else {
  //     toast.error("Please select a file first");
  //   }

  // };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    // Check if a file is selected
    if (!file) {
      toast.error("Please select a file first");
      return;
    }

    // Extract file extension
    const fileExtension = file.name.split('.').pop().toLowerCase();

    // Check if the file type is valid
    if (fileExtension !== 'csv' && fileExtension !== 'xls' && fileExtension !== 'xlsx') {
      toast.error("Please select a valid CSV or Excel file");
      return;
    }

    try {
      await subscribeUsersBatch(file, formData.get("subPlan"));
      setFile(null);
      fileInputRef.current.value = '';
      toast.success("File Uploaded Successfully");
    } catch (error) {
      console.log("Inside failure", error);
      toast.error("Error uploading file");
    }
  };

  return (
    <>
      <Layout>
        <div className="rows">
          <div className="containers table-responsive" align="center">
            {/* <!--Table--> */}
            <form onSubmit={handleFileUpload}>
              <table
                id="example1"
                className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
                style={{ width: "50%" }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="black-text text-darken-2"
                      style={{
                        fontFamily: "Cambria, Georgia, serif",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 500,
                        textAlign: "center",
                        backgroundColor: "#3f5c9a",
                      }}
                    >
                      Upload Base
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Mode
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <select className="form-control" name="subMode">
                        <option value="SMS.">SMS</option>
                        <option value="USSD.">USSD</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Send Subscription Sms
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <select className="form-control" name="subSms">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Send Content Sms
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <select className="form-control" name="contentSms">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Select Pack
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <select className="form-control" name="subPlan">
                        <option value="1">1</option>
                        <option value="7">7</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Select Base file
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={handleFileChange}
                      />
                      <a style={{ color: "blue" }} href="sms_sample/sample.csv">
                        Check sample file
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      className="black-text text-darken-2"
                      style={{
                        fontFamily: "Cambria, Georgia, serif",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      <button
                        name="configure"
                        type="submit"
                        className="btn btn-primary waves-light"
                      >
                        Upload
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <table
              id="example"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Plan</th>
                  <th className="black-text text-darken-2">Count</th>
                  <th
                    className="black-text text-darken-2"
                    style={{ width: "200px" }}
                  >
                    #
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
        <div
          id="myModalDilog"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="myModalLabel"
                  style={{ color: "black" }}
                >
                  {" "}
                  Are you sure you want to delete this detail?
                </h5>
              </div>

              <div className="modal-footer">
                {/* <form> */}
                <input type="hidden" name="deleteId" id="deleteId" />
                <input
                  type="submit"
                  className="btn btn-default"
                  name="btnDelete"
                />
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UploadBase;
