import React, { useCallback, useContext, useEffect, useState } from "react";
import { getMISData } from "../services/mis";
import { useSession } from "./SessionProvider";

const MISContext = React.createContext({
    misRecords: [],
    filter: null,
    getMISRecords: () => [],
    updateFilter: () => { }
});

export const MISProvider = ({ children }) => {
    const { user } = useSession();
    const [misRecords, setMisRecords] = useState([]);
    const [filter, setFilter] = useState({
        year: "",
        month: ""
    });

    const getMISRecords = useCallback(async (filter) => {
        try {
            const inputFilter = filter || {};
            const misData = await getMISData(inputFilter);

            setMisRecords(misData.data);
        } catch (e) {
            console.log(e.message);
        }
    }, []);

    function updateFilter(filter) {
        setFilter(filter);
    }

    useEffect(() => {
        if (filter && user?.id) {
            getMISRecords(filter);
        }
    }, [getMISRecords, filter, user]);

    return (<MISContext.Provider value={{
        getMISRecords,
        updateFilter,
        misRecords,
        filter
    }}>
        {children}
    </MISContext.Provider>)
}

export function useMIS() {
    const misContext = useContext(MISContext);

    return misContext;
}