import api from './interceptor';

export async function getMISData(filter) {
    try {
        const { year, month } = filter;
        const response = await api.get("/user/mis-info", { params: { year, month } });

        return response.data;
    } catch (e) {
        console.log(e.message);
    }
}