import React, { useCallback, useContext, useState } from "react";
import { getSubscriptionData } from "../services/subscription";

const SubscriptionContext = React.createContext({
    subscriptionsSummary: {},
    subscriptions: [],
    getSubscriptions: () => new Promise(),
});

export const SubscriptionProvider = ({ children }) => {
    const [subscriptionData, setSubscriptionData] = useState({ subscriptionsSummary: {}, subscriptions: [] });

    const getSubscriptions = useCallback(async (date) => {
        if (!date) {
            return;
        }

        try {
            const { data } = await getSubscriptionData(date);

            setSubscriptionData(data);
        } catch (e) {
            console.log(e.message);
        }
    }, []);

    return (<SubscriptionContext.Provider value={{
        ...subscriptionData,
        getSubscriptions,
    }}>
        {children}
    </SubscriptionContext.Provider>)
}

export function useSubscription() {
    const subscriptionContext = useContext(SubscriptionContext);

    return subscriptionContext;
}