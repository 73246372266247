import React from "react";
import Layout from "../components/layout/Layout";
import Navbar from "./layout/Navbar";
import { useHome } from "../providers/HomeProvider";
import { Link } from "react-router-dom";

const Home = () => {
  const { homeRecords, updateFilter } = useHome();

  return (
    <>
      <Layout>
        <Navbar updateFilter={updateFilter} />
        <div style={{ marginTop: '50px' }}></div>
        <div className="rows">
          <div className="containers table-responsive">
            {/* <!--Table--> */}
            <table
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Service</th>
                  <th className="black-text text-darken-2">
                    Sub
                    <br /> Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Success
                    <br /> Sub Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Fail
                    <br /> Sub Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Sub
                    <br />
                    Revenue
                  </th>
                  <th className="black-text text-darken-2">
                    Renewal
                    <br />
                    Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Renewal
                    <br />
                    Success Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Renewal
                    <br />
                    Fail Hits
                  </th>
                  <th className="black-text text-darken-2">
                    Renewal
                    <br />
                    Revenue
                  </th>
                  <th className="black-text text-darken-2">
                    Active
                    <br />
                    Base
                  </th>
                  <th className="black-text text-darken-2">
                    Daily
                    <br />
                    Unique
                  </th>
                  <th className="black-text text-darken-2">
                    Total
                    <br />
                    Revenue
                  </th>
                  <th className="black-text text-darken-2">Unsub</th>
                </tr>
              </thead>
              <tbody>
                {homeRecords.map(record => (
                  <tr key={record.date}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.service}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      <Link to={`/sub-details?date=${record.date}`} style={record.successful_sub_hits ? { color: "#337ab7", textDecoration: "underline" } : {}}>
                        {record.successful_sub_hits}
                      </Link>
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.failed_sub_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.successful_renewal_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.failed_renewal_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.active_user_base}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.daily_unique_users}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.total_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.unsubscriptions}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={14}>
                    Showing {homeRecords.length} of {homeRecords.length} record(s)
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
