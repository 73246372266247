import React, { useCallback, useContext, useState } from "react";
import { getNumberDetailData } from "../services/number-detail";

const NumberDetailContext = React.createContext({
    subscriptions: [],
    billing: [],
    getNumberDetails: () => new Promise()
});

export const NumberDetailProvider = ({ children }) => {
    const [numberDetailData, setNumberDetailData] = useState({ subscriptions: [], billing: [] });

    const getNumberDetails = useCallback(async (mobileNumber) => {
        if (!mobileNumber) {
            return;
        }

        try {
            const { data } = await getNumberDetailData(mobileNumber);

            setNumberDetailData(data);
        } catch (e) {
            console.log(e.message);
        }
    }, []);

    return (<NumberDetailContext.Provider value={{
        ...numberDetailData,
        getNumberDetails,
    }}>
        {children}
    </NumberDetailContext.Provider>)
}

export function useNumberDetail() {
    const numberDetailContext = useContext(NumberDetailContext);

    return numberDetailContext;
}