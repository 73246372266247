import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import Navbar from "./layout/Navbar";
import { useSubscription } from "../providers/SubscriptionProvider";
import { useSearchParams } from "react-router-dom";

const SubscriptionDetail = () => {
  const [searchParams] = useSearchParams();
  const { subscriptionsSummary, subscriptions, getSubscriptions } = useSubscription();

  useEffect(() => {
    if (searchParams) {
      getSubscriptions(searchParams.get("date"));
    }
  }, [getSubscriptions, searchParams]);

  return (
    <>
      <Layout>
        <Navbar />
        <div style={{ marginTop: '50px' }}></div>
        <div className="rows">
          <div className="containers table-responsive">
            <table
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">Total Sub</th>
                  <th className="black-text text-darken-2">SMS</th>
                  <th className="black-text text-darken-2">USSD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="ns" style={{ textAlign: "center" }}>
                    {subscriptionsSummary.date}
                  </td>
                  <td className="ns" style={{ textAlign: "center" }}>
                    {subscriptionsSummary.totalSubs}
                  </td>
                  <td className="ns" style={{ textAlign: "center" }}>
                    {subscriptionsSummary.sms}
                  </td>
                  <td className="ns" style={{ textAlign: "center" }}>
                    {subscriptionsSummary.ussd}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">MSISDN</th>
                  <th className="black-text text-darken-2">Service</th>
                  <th className="black-text text-darken-2">Amount</th>
                  <th className="black-text text-darken-2">Mode</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.map(record => (
                  <tr key={record.subscription_datetime}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mobileNumber}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.serviceName}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.amount}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.mode}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={14}>
                    Showing {subscriptions.length} of {subscriptions.length} record(s)
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SubscriptionDetail;
