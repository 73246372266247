import api from './interceptor';

export async function getNumberDetailData(mobileNumber) {
    try {
        const response = await api.get("/user/number-detail", { params: { mobileNumber } });

        return response.data;
    } catch (e) {
        console.log(e.message);
    }
}
