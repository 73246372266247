import React, { useRef, useState } from "react";
import Layout from "./layout/Layout";
import { toast } from 'react-toastify';
import { unsubscribeUsersBatch } from "../services/user";

toast.configure({
  position: 'top-right', // Position of toast messages
  autoClose: 5000,           // Auto close time in milliseconds
  hideProgressBar: false,     // Whether to show progress bar
  closeOnClick: true,         // Close toast on click
  pauseOnHover: true,         // Pause timer when hovering over toast
  draggable: true,            // Make toast draggable
  draggablePercent: 60,       // Percentage of toast width draggable
});

const UnSub = () => {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    try {
      await unsubscribeUsersBatch(file, formData.get("ise2code").split(","));
      setFile(null);
      fileInputRef.current.value = '';
      if (file) {
        toast.success("File Uploaded Successfully");
      } else {
        toast.success("ISE2 Codes Uploaded Successfully");
      }
    } catch (error) {
      console.log("Inside failure", error);
      toast.error("Error uploading file");
    }
  };

  return (
    <>
      <Layout>
        <div
          style={{
            margin: "50px 0",
          }}
        ></div>
        <div className="rows">
          <div className="containers table-responsive" align="center">
            {/* <!--Table--> */}
            <form onSubmit={handleFileUpload}>
              <table
                id="example1"
                className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
                style={{ width: "50%" }}
              >
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="black-text text-darken-2"
                      style={{
                        fontFamily: "Cambria, Georgia, serif",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 500,
                        textAlign: "center",
                        backgroundColor: "#3f5c9a",
                      }}
                    >
                      Upload Base
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Enter ISE2 code (comma separated)
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <input
                        type="text"
                        name="ise2code"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Or
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      Select Base file
                    </td>
                    <td
                      className="ns"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={handleFileChange}
                      />
                      <a style={{ color: "blue" }} href="sms_sample/unsub.csv">
                        Check sample file
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      className="black-text text-darken-2"
                      style={{
                        fontFamily: "Cambria, Georgia, serif",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontVariant: "normal",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >
                      <button
                        name="configure"
                        type="submit"
                        className="btn btn-primary waves-light"
                      >
                        Upload
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <table
              id="example"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Mode</th>
                  <th className="black-text text-darken-2">Count</th>
                  <th
                    className="black-text text-darken-2"
                    style={{ width: "200px" }}
                  >
                    #
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    CC
                  </td>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    1
                  </td>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  ></td>
                </tr>
                <tr>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    USSD
                  </td>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    7
                  </td>
                  <td
                    className="ns"
                    style={{
                      textAlign: "center",
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          id="myModalDilog"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="myModalLabel"
                  style={{ color: "black" }}
                >
                  {" "}
                  Are you sure you want to delete this detail?
                </h5>
              </div>

              <div className="modal-footer">
                <form action="" method="post">
                  <input
                    type="submit"
                    className="btn btn-default"
                    name="btnDelete"
                  />
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!--Table--> */}
      </Layout>
    </>
  );
};

export default UnSub;
