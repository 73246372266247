import axios from "./interceptor";

export const loginApi = async (userData) => {
    try {
        const response = await axios.post("/auth/adminLogin", userData);
        return response.data.data.token;
    } catch (e) {
        throw e.response.data;
    }
};

export const sendOtp = async (data) => {
    try {
        const response = await axios.post("/auth/sendOtp", data);
        return response.data.message;
    } catch (e) {
        throw e.response.data;
    }
};

export const login = async (data) => {
    try {
        const response = await axios.post("/auth/verifyOtp", data);
        return response.data;
    } catch (e) {
        throw e.response.data;
    }
};