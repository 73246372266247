import React from 'react';
import { Form } from 'react-bootstrap';
import "./Navbar.css";

const Navbar = (props) => {
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const year = Number.parseInt(formData.get("year"));
    const month = Number.parseInt(formData.get("month"));

    if (!isNaN(year) && !isNaN(month)) {
      props.updateFilter({ year, month });
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="rows">
            <Form onSubmit={handleSubmit}>
              <Form.Control as="select" name="year" className='select'>
                <option value={null}>Select Year</option>
                <option value={2018}>2018</option>
                <option value={2019}>2019</option>
                <option value={2020}>2020</option>
                <option value={2021}>2021</option>
                <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
                <option value={2025}>2025</option>
              </Form.Control>
              <Form.Control as="select" name="month" className='select'>
                <option value={null}>Select Month</option>
                <option value={0}>January</option>
                <option value={1}>February</option>
                <option value={2}>March</option>
                <option value={3}>April</option>
                <option value={4}>May</option>
                <option value={5}>June</option>
                <option value={6}>July</option>
                <option value={7}>August</option>
                <option value={8}>September</option>
                <option value={9}>October</option>
                <option value={10}>November</option>
                <option value={11}>December</option>
              </Form.Control>

              <button
                name="submit"
                type="submit"
                className="btn btn-primary waves-light"
              >
                Search
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar