import React from "react";
import Layout from "./layout/Layout";
import Navbar from "./layout/Navbar";
import { useMIS } from "../providers/MISProvider";

const Mis = () => {
  const { misRecords, updateFilter } = useMIS();

  return (
    <>
      <Layout>
        <Navbar updateFilter={updateFilter} />
        <div style={{ marginTop: '50px' }}></div>
        <div className="rows">
          <div className="containers table-responsive">
            {/* <!--Table--> */}
            <table
              id="example"
              className="display nowrap ns z-depth-1 table table-striped table-bordered row-border order-column table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th className="black-text text-darken-2">Date</th>
                  <th className="black-text text-darken-2">
                    Active
                    <br />
                    Base
                  </th>
                  <th className="black-text text-darken-2">
                    Daily
                    <br />
                    UU
                  </th>
                  <th className="black-text text-darken-2">
                    Total <br />
                    New <br />
                    Subscriptions
                    <br /> Request
                  </th>
                  <th className="black-text text-darken-2">
                    Charged <br />
                    New <br />
                    Subscription
                  </th>
                  <th className="black-text text-darken-2">
                    New <br />
                    Subscription <br />
                    Revenue
                  </th>
                  <th className="black-text text-darken-2">
                    Total <br />
                    Renewal
                    <br /> Subscriptions
                  </th>
                  <th className="black-text text-darken-2">
                    Renewal <br />
                    Subscription
                    <br /> Revenue
                  </th>
                  <th className="black-text text-darken-2">
                    Total <br />
                    Revenue <br />
                    Leones
                  </th>
                  {/* <th className="black-text text-darken-2">
                    Total <br />
                    Revenue $
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {misRecords.map(record => (
                  <tr key={record.date}>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.date}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.active_user_base}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.daily_unique_users}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.successful_sub_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.sub_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.successful_renewal_hits}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.renewal_revenue}
                    </td>
                    <td className="ns" style={{ textAlign: "center" }}>
                      {record.total_revenue}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Mis;
